<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="h2">
                Ordenes y ventas en
                {{ selectedMerchant.merchantType | merchantInfo("type") }}
                <span class="text-primary">
                    {{ selectedMerchant.name }}
                </span>
            </h1>

            <p class="mt-2">
                Por defecto el sistema consulta las ventas es decir las ordenes
                completadas. Puedes consultar también las ordenes pendientes,
                ordenes por fecha y ordenes por vendedor ajustando los filtros
                de búsqueda.
            </p>
        </div>

        <div class="d-flex flex-wrap mt-2">
            <div class="col-12">
                <orders-filters
                    v-model="query"
                    :enabled-filters="$data.$enabledFilters"
                    @enter="reloadItems"
                />
                <div class="d-flex justify-content-center">
                    <vs-button v-show="query" relief @click="reloadItems">
                        Consultar 🔎
                    </vs-button>
                </div>
            </div>

            <div
                class="d-flex justify-content-between flex-column flex-lg-row col-12 mt-2"
            >
                <div class="d-flex justify-content-around">
                    <tooltip-button
                        v-if="
                            $ability.hasScope('order:Complete') &&
                            selected.length === 1
                        "
                        icon="🏁"
                        message="Completar orden"
                        @click="completeOrderComponent"
                    />

                    <tooltip-button
                        v-show="selected.length"
                        icon="🧹"
                        message="Limpiar selección"
                        @click="clearSelected"
                    />
                </div>

                <div class="d-flex align-items-end justify-content-around">
                    <vs-pagination
                        v-model="currentPage"
                        :length="pagesNumber"
                        @input="listItems()"
                    />

                    <tooltip-redirect
                        v-if="
                            $ability.hasScope('order:Create') ||
                            $ability.hasScope('order:CreateWithGlobalPrices')
                        "
                        to="/app/orders/new-in-merchant"
                        message="Registrar venta"
                    >
                    </tooltip-redirect>
                </div>
            </div>
        </div>

        <div v-show="showTable" class="w-100">
            <div class="py-3">
                <div class="">
                    <span class="h4">
                        Número de ordenes encontradas para los filtros de
                        búsqueda
                        <span class="text-primary font-weight-bold">
                            {{ itemsCurrent.length }}
                        </span>
                    </span>
                    <br />
                    <fragment v-if="itemsCurrent.length">
                        <span class="h4">
                            Total
                            <span class="text-primary font-weight-bold">
                                {{ money.total | money }}
                            </span>
                        </span>
                        <br />
                        <div class="d-flex flex-wrap">
                            <span v-if="money.cash">
                                Efectivo: &nbsp;
                                {{ money.cash | money }}&nbsp;
                            </span>
                            <span v-if="money.daviplata">
                                | &nbsp; Daviplata: &nbsp;
                                {{ money.daviplata | money }}&nbsp;
                            </span>
                            <span v-if="money.nequi">
                                | &nbsp; Nequi: &nbsp;
                                {{ money.nequi | money }}&nbsp;
                            </span>
                            <span v-if="money.terminal">
                                | &nbsp; Datáfono: &nbsp;
                                {{ money.terminal | money }}&nbsp;
                            </span>
                            <span v-if="money.terminal1">
                                | &nbsp; Datáfono 1: &nbsp;
                                {{ money.terminal1 | money }}&nbsp;
                            </span>
                            <span v-if="money.terminal2">
                                | &nbsp; Datáfono 2: &nbsp;
                                {{ money.terminal2 | money }}&nbsp;
                            </span>
                            <span v-if="money.terminal3">
                                | &nbsp; Datáfono 3: &nbsp;
                                {{ money.terminal3 | money }}&nbsp;
                            </span>
                        </div>
                    </fragment>
                </div>
            </div>
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="multi"
                responsive
                outlined
                no-border-collapse
                hover
                selectable
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="
                                $enabledEcommerceOptions
                                    ? `/app/orders/${selectedMerchant.merchantType}/${selectedMerchant.merchantId}/detail/${data.item.id}`
                                    : `/app/orders/detail/${data.item.id}`
                            "
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <span> {{ data.item.id }}</span>
                        </router-link>
                    </div>
                </template>
                <template #cell(deliveryStatusMessage)="data">
                    <small
                        class="text-center font-weight-normal"
                        :class="
                            $data.$deliveryStatusesMessageClass[
                                data.item.deliveryStatus
                            ]
                        "
                    >
                        {{ data.item.deliveryStatusMessage }}
                    </small>
                </template>

                <template #cell(deliveryOptionMessage)="data">
                    <small
                        class="text-center"
                        :class="
                            data.item.deliveryOption === 'home'
                                ? 'text-info'
                                : ''
                        "
                    >
                        {{ data.item.deliveryOptionMessage }}
                    </small>
                </template>
                <template #cell(paymentStatusMessage)="data">
                    <small
                        class="text-center"
                        :class="
                            $data.$paymentStatusesMessageClass[
                                data.item.paymentStatus
                            ]
                        "
                    >
                        {{ data.item.paymentStatusMessage }}
                    </small>
                </template>
                <template #cell(paymentMethodMessage)="data">
                    <small class="text-center">
                        {{ data.item.paymentMethodMessage }}
                    </small>
                </template>
                <template #cell(customer)="data">
                    <small v-if="data.item.customer" class="text-center">
                        {{
                            `${data.item.customer.firstName} ${data.item.customer.lastName} / ${data.item.customer.phone}`
                        }}
                    </small>
                    <small v-else> Sin cliente Asociado </small>
                </template>
                <template #cell(total)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.total | money }}
                    </small>
                </template>
                <template #cell(deliveryAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.deliveryAt | dateSimple }}
                    </small>
                </template>
                <template #cell(createdAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.createdAt | date }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { deliveryStatusesMessageClass } from "@/common/fields/delivery-status-codes";
import { paymentStatusesMessageClass } from "@/common/fields/payment-status-codes";
import { calculateOrdersPaymentDistribution } from "@/common/lib/money";
import { EMPTY_MONEY_CONFIG } from "@/common/lib/money";

import OrdersFilters from "@/components/orders/OrdersFilters.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "IndexOrderOwn",
    components: { OrdersFilters, TooltipRedirect, TooltipButton },
    layout: "app",
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        money: EMPTY_MONEY_CONFIG,
        $deliveryStatusesMessageClass: deliveryStatusesMessageClass,
        $paymentStatusesMessageClass: paymentStatusesMessageClass,
        $fields: [
            { key: "id", label: "ID", sortable: false },
            {
                key: "deliveryStatusMessage",
                label: "Estado",
                sortable: true
            },
            {
                key: "deliveryOptionMessage",
                label: "Opción de entrega",
                sortable: false
            },
            {
                key: "paymentStatusMessage",
                label: "Estado de pago",
                sortable: false
            },
            {
                key: "paymentMethodMessage",
                label: "Método de pago",
                sortable: false
            },
            { key: "total", label: "Total", sortable: true },
            { key: "createdAt", label: "📆 Creación", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true },
            { key: "deliveryAt", label: "📆 Entrega", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        loadedAllData: false,
        isLoadingAllData: false,
        selected: [],
        query: {
            deliveryStatus: "cmp"
        },
        $enabledFilters: [
            "uid",
            "dateRange",
            "timeAgo",
            "weekAgo",
            "monthAgo",
            "origin",
            "deliveryStatus",
            "paymentStatus",
            "sellerId"
        ]
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"]),
        ...mapState("commons", ["selectedMerchant"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("order:ListOwn", "/app", true))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("orders", ["listOrdersOwnPaginated", "completeOrder"]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items, pagination } = await this.listOrdersOwnPaginated(
                    {
                        merchantType: this.selectedMerchant.merchantType,
                        merchantId: this.selectedMerchant.merchantId,
                        page: this.currentPage,
                        query: this.query,
                        complete
                    }
                );
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.money = calculateOrdersPaymentDistribution(items);
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async completeOrderComponent() {
            const id = this.selected[0].id;
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: `Vas a completar la orden ${id}`,
                icon: "info",
                text: `Al completar la orden vas a. 
Actualizar el estado de entrega a completado. 
Actualizar el estado de pago a completado.
Actualizar el valor pagado al valor total de la orden.
Decrementar la cantidad de productos de la orden en el inventario del comercio asignado. En caso de no haber sido decrementado anteriormente.`,
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (!dialogResult.isConfirmed) return;

            this.loaderInstance = this.$vs.loading({ type: "square" });

            try {
                await this.completeOrder({
                    id
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        },
        goToRefactor() {
            const id = this.selected[0].id;
            window.open(`/app/orders/refactor/${id}`, "_blank").focus();
        }
    }
};
</script>
